var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"header-tag":"header","footer-tag":"footer"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","width":"100%"}},[_c('h6',{staticClass:"mb-0"},[_vm._v("Device Location")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.btnUpdate == true},on:{"click":_vm.downloadData}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.btnUpdate == true),expression:"btnUpdate == true"}],attrs:{"small":""}}),_vm._v(" Update ")],1)],1)]},proxy:true}])},[(_vm.statusLoaded == null)?[_c('div',{staticClass:"d-flex justify-content-center mb-3"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1)]:[_c('GmapMap',{ref:"map2",staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":12},on:{"click":_vm.addPoint,"tilesloaded":_vm.fitMarkers}},[_vm._l((_vm.geofences),function(geofence,index){return _c('GmapPolygon',{key:index,attrs:{"path":geofence.points,"options":{
        fillColor: geofence.color,
        fillOpacity: .5,
        strokeColor: geofence.color,
        strokeOpacity: 1,
        strokeWeight: 2,
      }},on:{"click":function($event){return _vm.selectGeofence(geofence)}}})}),_c('GmapMarker',{attrs:{"position":_vm.center,"zoom":1,"icon":{
        url: require('../../../../../public/media/markers/man2.png'),
      }}}),_vm._l((_vm.displayedMarkers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.location,"clickable":true,"draggable":true,"icon":{
          url: require('../../../../../public/media/markers/marker.png'),
        }},on:{"click":function($event){m.showTooltip = true}}},[_c('GmapInfoWindow',{attrs:{"opened":m.showTooltip},on:{"closeclick":function($event){m.showTooltip = false}}},[_c('div',[_vm._v(_vm._s(_vm.formatDate(m.created_at)))])])],1)})],2),_c('br'),_c('b-button',{on:{"click":_vm.addNewGeofence}},[_vm._v("Add new geofence")]),_c('b-button',{on:{"click":_vm.clearAllGeofences}},[_vm._v("Clear all geofences")]),_c('b-button',{on:{"click":_vm.deleteSelectedGeofence}},[_vm._v("Delete selected geofence")]),_c('br'),_c('b-table',{attrs:{"items":_vm.tableItems,"fields":_vm.polygonsTableFields}}),_c('br'),_c('div',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],attrs:{"variant":"primary"}},[_vm._v("Show location details")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-1"}},[_c('b-card',[_c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.markers,"tbody-tr-class":_vm.rowClassCallback,"fields":_vm.tableFields},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.created_at))+" ")]}}])})],1)])],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }