<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
        <h6 class="mb-0">Device Location</h6>
        <b-button variant="success" v-on:click="downloadData" :disabled="btnUpdate == true">
          <b-spinner small v-show="btnUpdate == true"></b-spinner>
          Update
        </b-button>
      </div>
    </template>

    <template v-if="statusLoaded == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary"> </b-spinner>
      </div>
    </template>
    <template v-else>
      <GmapMap :center="center" @click="addPoint" :zoom="12" ref="map2" style="width:100%;  height: 400px;"
        @tilesloaded="fitMarkers">
        <GmapPolygon v-for="(geofence, index) in geofences" :key="index" :path="geofence.points" :options="{
          fillColor: geofence.color,
          fillOpacity: .5,
          strokeColor: geofence.color,
          strokeOpacity: 1,
          strokeWeight: 2,
        }" @click="selectGeofence(geofence)" />
        <GmapMarker :position="center" :zoom="1" :icon="{
          url: require('../../../../../public/media/markers/man2.png'),
        }" />
        <GmapMarker v-for="(m, index) in displayedMarkers" :key="index" :position="m.location" :clickable="true"
          :draggable="true" :icon="{
            url: require('../../../../../public/media/markers/marker.png'),
          }" @click="m.showTooltip = true">
          <GmapInfoWindow :opened="m.showTooltip" @closeclick="m.showTooltip = false">
            <div>{{ formatDate(m.created_at) }}</div>
          </GmapInfoWindow>
        </GmapMarker>
      </GmapMap>
      <br>
      <b-button @click="addNewGeofence">Add new geofence</b-button>
      <b-button @click="clearAllGeofences">Clear all geofences</b-button>
      <b-button @click="deleteSelectedGeofence">Delete selected geofence</b-button>
      <br>
      <b-table :items="tableItems" :fields="polygonsTableFields"></b-table>
      <br>
      <div>
        <b-button v-b-toggle.collapse-1 variant="primary">Show location details</b-button>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <div>
              <b-table striped hover :items="markers" @row-clicked="rowClicked" :tbody-tr-class="rowClassCallback"
                :fields="tableFields">
                <template v-slot:cell(created_at)="data">
                  {{ formatDate(data.item.created_at) }}
                </template>
              </b-table>
            </div>
          </b-card>
        </b-collapse>
      </div>

    </template>
  </b-card>
</template>

<script>
import { GET_FINDMY_LOCATION } from "@/core/services/store/devices.module";
import { gmapApi } from 'vue2-google-maps';
import { mapGetters } from "vuex";
export default {
  name: "suitch-map-widget",
  components: {},
  props: {
    device: null,
    showName: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "setMessage") {
        // verify if the type of message is for this widget
        if (
          this.device != null &&
          state.realtime.last_message.type == "location" &&
          state.realtime.last_message.token == this.device.token
        ) {
          // call again the action to get the status
          this.downloadData();
        }
      }
    });
    this.downloadData();
  },
  data() {
    return {
      activeGeofence: null,
      selectedGeofence: null,
      geofences: [],
      current_device: { connection_type: -1 },
      statusLoaded: null,
      center: { lat: 25.686613, lng: -100.316116 },
      markers: [],
      polygonsTableFields: [
        { key: 'color', label: 'Color' },
        { key: 'count', label: 'Marker Count' },
      ],
      show: "day",
      markersLoaded: false,
      tableFields: [
        { key: "location", label: "Location" },
        { key: "created_at", label: "Updated At", formatter: "formatDate" },
      ],
      selectedMarker: null,
      btnUpdate: false,
    };
  },
  methods: {
    addPoint(e) {
      if (this.activeGeofence !== null) {
        this.activeGeofence.points.push(e.latLng);
        this.updateTable();
      }
    },
    addNewGeofence() {
      const newGeofence = { points: [], color: this.randomColor() };
      this.geofences.push(newGeofence);
      this.activeGeofence = newGeofence;
      this.updateTable();
    },
    selectGeofence(geofence) {
      console.log(geofence);
      this.selectedGeofence = geofence;
    },
    clearAllGeofences() {
      this.geofences = [];
      this.activeGeofence = null;
      this.updateTable();
    },
    deleteSelectedGeofence() {
      if (this.selectedGeofence !== null) {
        const index = this.geofences.indexOf(this.selectedGeofence);
        if (index > -1) {
          this.geofences.splice(index, 1);
          this.selectedGeofence = null;
          this.updateTable();
        }
      }
    },
    updateTable() {
      this.tableItems = this.geofences.map(geofence => {
        const polygon = new window.google.maps.Polygon({ paths: geofence.points });
        const count = this.markers.reduce((count, marker) => {
          if (window.google.maps.geometry.poly.containsLocation(marker.location, polygon)) {
            count++;
          }
          return count;
        }, 0);
        return { color: geofence.color, count };
      });
    },
    randomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    rowClassCallback(item, type) {
      if (type === "row") {
        return this.isSelected(item) ? "map-selected-row" : "";
      }
      return "";
    },
    rowClicked(clickedItem) {
      if (this.selectedMarker === clickedItem) {
        this.selectedMarker = null; // Unselect if it's already selected
      } else {
        this.selectedMarker = clickedItem;
      }
    },
    formatDate(dateString) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      let date;
      try {
        date = new Date(dateString);
        if (isNaN(date)) {
          throw new Error('Invalid date');
        }
        return new Intl.DateTimeFormat(undefined, options).format(date);
      } catch (err) {
        console.error('Error formatting date:', err);
        return null;
      }
    },
    fitMarkers() {
      if (this.markersLoaded) {
        return;
      }
      if (this.markers.length > 2 && window.google.maps != undefined && this.$refs.map2 != undefined) {
        const bounds = new window.google.maps.LatLngBounds();
        for (let m of this.markers) {
          bounds.extend(m.location);
        }
        this.$refs.map2.fitBounds(bounds);
        this.markersLoaded = true;
      }
    },
    downloadData() {
      this.btnUpdate = true;
      this.markers = [];
      this.markersLoaded = false;
      if (this.device != null) {
        this.$store
          .dispatch(GET_FINDMY_LOCATION, { device_token: this.device.token })
          // go to which page after successfully login
          .then((data) => {
            this.getLocationPermission();
            // data is an array of objects with the next shape {value:base64, created_at: date}
            // decode the base64 of value field of each object and store in a local object
            const imarkers = data.map((item) => {
              const decodedValue = JSON.parse(atob(item.value));
              const { lat, lon: lng } = decodedValue;

              return {
                location: { lat, lng },
                created_at: item.created_at,
                showTooltip: false,
              };
            });
            this.markers = imarkers;
            this.statusLoaded = {};
            if (data == undefined || data == null) {
              // when null
            } else {
              // populate information
            }
            this.btnUpdate = false;
            this.fitMarkers();
          });
      }
    },
    isSelected(item) {
      return this.selectedMarker === item;
    },
    getLocationPermission() {
      // Check for Geolocation API permissions
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          // The permission was granted, you can get the geolocation
          this.geolocate();
        } else if (result.state === 'prompt') {
          // The permission is not decided yet, you can prompt the user
          this.geolocate();
        } else if (result.state === 'denied') {
          // The permission was denied
          console.log('Location permission was denied.');
        }
      });
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const bounds = new window.google.maps.LatLngBounds();
        if (this.markers.length > 2) {
          for (let m of this.markers) {
            bounds.extend(m.location);
          }
          this.$refs.map2.fitBounds(bounds);
        }
      });
    },
  },
  computed: {
    google: gmapApi,
    ...mapGetters(["layoutConfig"]),
    displayedMarkers() {
      if (this.selectedMarker) {
        return [this.selectedMarker];
      } else {
        return this.markers;
      }
    },
  },
  watch: {
    markers(markers) {
      markers;
      // if (markers.length > 2) {
      //   const bounds = new window.google.maps.LatLngBounds();
      //   for (let m of markers) {
      //     bounds.extend(m.location);
      //   }
      //   this.$refs.map2.fitBounds(bounds);
      // }
    },
    device: function (oldval, newval) {
      oldval, newval;
      this.downloadData();
    },
  },
};
</script>
